import React, { Suspense, lazy} from 'react'
import { Route, Routes as Switch, useLocation } from 'react-router-dom'
import { Splash } from '../@uikit'
import { Fade } from '@chakra-ui/react'
import { useApp } from '../hooks'
import ScrollToTop from './ScrollToTop'
const Login = lazy(() => import('../pages/Auth/Login'))
const Register = lazy(() => import('../pages/Auth/Register'))
const ForgetPassword = lazy(() => import('../pages/Auth/ForgetPassword'))
const GeneralLayout = lazy(() => import('../layouts/GeneralLayout'))
const DashboardLayout = lazy(() => import('../layouts/DashboardLayout'))
const CourseLayout = lazy(() => import('../layouts/CourseLayout'))

const Routes = () => {    
    
    const auth = useApp();
    const location = useLocation()
    
    if(auth.loading)
    {
        return(
            <Fade in={true}>
                <Splash />
            </Fade>
        )
    }        


    
        return(
            <Fade in={true} key={location?.key}>
                <Suspense fallback={<Splash />}>                    
                    <ScrollToTop />
                    <Switch>
                        {
                            window.is_empty(auth.user)  &&
                            <>
                                <Route exact path="/login" element={<Login/>} />                        
                                <Route exact path="/register" element={<Register/>} />                        
                                <Route exact path="/forget-password" element={<ForgetPassword/>} />
                            </>
                        }
                        <Route path="/*" element={<GeneralLayout/>} />
                        {
                            auth?.user &&
                            <>
                                <Route path="account/*" element={<DashboardLayout/>} />
                                <Route path="account/courses/:slug" element={<CourseLayout/>} />
                            </>
                        }
                    </Switch>                    
                </Suspense>
            </Fade> 
        )
        
}

export default Routes;