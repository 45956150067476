import React from "react"
import { Box, Heading, HStack, IconButton, Text } from "@chakra-ui/react"
import { createStandaloneToast } from '@chakra-ui/toast'
import { Icon } from "../@uikit"
import { v4 as uuidv4 } from 'uuid';


const { toast } = createStandaloneToast()

export const useToast ={    
    error: (message, title = "", position = null) => {
        // if (!toast.isActive(toast_id)) {        
            const toast_id = uuidv4()
            toast({
                render: () => {
                return (
                    <Box bg="red.500" shadow="sm" w="100%" px={5} py={3}   rounded="md" mb={1}  position="relative">
                        <HStack alignItems="flex-start">
                            <Box>
                                <Icon fontSize={25} name="error-warning-fill" color="whiteAlpha.800" />
                            </Box>
                            <Box flex={1} pt={2}>
                                {title && <Heading mb={2} size="xs" color="whiteAlpha.800">{title}</Heading>}
                                <Text color="white" fontSize="sm">{message}</Text>
                            </Box>                            
                        </HStack>
                        <IconButton onClick={() => toast.close(toast_id)} size="xs" colorScheme="red" rounded="full" variant="solid" position="absolute" right={2} top={2} icon={<Icon name="close-fill" color="currentcolor" />} />
                    </Box>
                )
                },   
                id: toast_id, 
                isClosable: true,      
                position: position || "top-right"
            })
        // }
    },       
    success: (message, title,  position = null) => {
        const toast_id = uuidv4()
        toast({
            render: () => {
              return (
                <Box bg="green.700"  bottom={0} shadow="md" w="100%" px={5} py={3}  rounded="md" mb={1}>
                    <HStack alignItems="flex-start">
                            <Box>
                                <Icon fontSize={25} name="checkbox-circle-fill" color="whiteAlpha.800" />
                            </Box>
                            <Box flex={1} pt={2}>
                                {title && <Heading mb={2} size="xs" color="whiteAlpha.800">{title}</Heading>}
                                <Text color="white" fontSize="sm">{message}</Text>
                            </Box>                            
                        </HStack>
                        <IconButton onClick={() => toast.close(toast_id)} size="xs" colorScheme="green" rounded="full" variant="ghost" position="absolute" right={2} top={2} icon={<Icon name="close-fill" color="white" />} />
                    
                </Box>
              )
            },
            status: "success",
            position: position || "top-right",
            duration: 3000
          })
    },
  };
