import {requestTimeout, handleResponse} from '../utils'

const apiUrl = process.env.REACT_APP_API_URL
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME


export const discountApi = {
    _check,
    _get,
    
    
};


async function _check(data) {    

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        },
        body: JSON.stringify(data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'discounts', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    
}


async function _get() {

   
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        }
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'discounts', requestOptions))                    
                .then(handleResponse)
                .then((response) => {    
                    return response
                })                
        
}

