export const headings = {    
    baseStyle: {
        fontSize: "32px",
        fontWeight: '700',
        color: 'gray.900',
    },
    sizes:{
        "course-heading":{
            fontSize: "15px",
            fontWeight: 600,
        },
        lg: {
            fontSize: "32px",
            fontWeight: '700',
        },
        md:{
            fontSize: "22px",
            fontWeight: '700',
        },
        sm:{
            fontSize: "17px"
        },
        xs:{
            fontSize: "14px"
            
        }
    },
    variants:{
        "page-header":{
            fontSize: '20px',
            fontWeight: '700',
            m:0
        },
        "page-header-small":{
            fontSize: '16px',
            fontWeight: '600',
            m:0
        }
    }
}