import { TopicConstants as Constants } from '../constants'
import { topicApi as api } from '../../api'

export const topicAction = {    
    get,    
    clear,
};

function get(slug) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._get(slug)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.GET, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function clear() {
    return dispatch => {   
        return new Promise((resolve, reject) => {     
            dispatch(success());
            resolve()
        })   
    }
    function success() { return { type: Constants.CLEAR } }
}