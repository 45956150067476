import { datepicker } from "./datepicker"
export const custom = {
    'html, body': {
        color: 'gray.700',
    },    
    a:{
        WebkitTransition: 'all 0.3s ease',
        MozTransition: 'all 0.3s ease',        
        OTransition: 'all 0.3s ease',        
        transition: 'all 0.3s ease',
        textDecoration: "none",
        // "&:hover":{
        //     textDecoration: "underline"
        // }        
    },
    '.dash-nav': {
        cursor: 'pointer',        
        position: 'relative',
        rounded: 'md',
        "span i":{
            WebkitTransition: 'all 0.3s ease',
            MozTransition: 'all 0.3s ease',        
            OTransition: 'all 0.3s ease',        
            transition: 'all 0.3s ease',
        },
        '&:hover':{
            color: "brand.500",
            'span i':{
                color: "brand.500",                
            },
        },
        '&.active': {
            // borderColor: 'green.500',
            // backgroundColor: 'brand.500',
            color: "brand.500",
            // color: 'rgba(255,255,255,0.95)',
            // "&:after":{
            //     content: '""',
            //     position: "absolute",
            //     right: 0,
            //     w: 0,
            //     h: 0,
            //     borderTop: "7px solid transparent",
            //     borderBottom: "7px solid transparent",
            //     borderRight: "7px solid white"
            // },
            'span i':{
                color: "brand.600"
            },
            '&:hover': {
                // borderColor: 'brand.500',
                // backgroundColor: 'brand.600',
                color: 'brand.500',
            },
            '&.green':{
                '&.active':{
                    borderColor: 'green.300',                    
                }
            }
        },
        
    },
    ".ql-toolbar":{
        "&.ql-snow":{
            bg: "gray.50",
            borderTopLeftRadius: "sm",
            borderTopRightRadius: "sm"
        }
    },
    ".ql-container.ql-snow":{
        borderBottomLeftRadius: "sm",
        borderBottomRightRadius: "sm",        
    },
    ".ql-toolbar.ql-snow + .ql-container.ql-snow .ql-editor":{        
        minH: '150px',
        maxH: '350px',
        fontSize: "sm",        
    },
    '.hover-reveal':{
            '&:hover':{
                '.hover-target':{
                    display: 'flex'
                }
            }
        },
    '::-webkit-scrollbar':{
            bg: '#f2f2f2',
            w: '4px',
            h: '4px'
        },            
    '::-webkit-scrollbar-thumb': {
            bg: '#C9C9C9',
            borderRadius: '5px'
        },            
    '::-webkit-scrollbar-thumb:hover, ::-webkit-scrollbar-thumb:active':{
            cursor: 'pointer'
    },
    ".sidebar-menu::-webkit-scrollbar":{
        bg: 'transparent',
        w: '6px',
        h: '6px'
    },            
    '.sidebar-menu::-webkit-scrollbar-thumb': {
        bg: 'rgba(0,0,0,0.5)',
        // bg: "red",
        visibility: 'hidden',
        borderRadius: '0px',                
    },            
    '.sidebar-menu:hover::-webkit-scrollbar-thumb':{
        visibility: 'visible'
    },

    ".sidebar-menu:hover::-webkit-scrollbar":{    
        w: '6px',
        h: '6px'
    },
    "react-player":{
        position: "absolute",
        left:0,
        top:0
    },
    "table.basic":{
        w: "100%",
        bg: "white",
        rounded: "lg",
        shadow: "sm",
        position: "relative",
        tfoot:{
            position: "sticky",
        },
        thead:{   
            zIndex: 2,
            borderColor: "gray.100",
            position: "sticky",
            top: 0,
            shadow: "custom",       
            borderBottom: "1px solid",
            tr:{
                borderBottom: "1px solid",
                borderColor: "gray.100",
            },
            th:{
                // top: 0,
                bg: "white",
                // position: "sticky",
                textAlign: 'left',
                textTransform: "uppercase",
                fontSize: "0.7rem",                       
                py:3,
                px:3,
                fontWeight: '600',
                color: 'gray.800',   
                "&:first-of-type":{
                    pl: 12
                },             
                borderBottom: "1px solid",
                borderColor: "gray.100",
                "&[data-align='right']":{
                    textAlign: "right"
                },    
                "&.bordered":{
                    borderLeft: "1px solid",
                    borderRight: "1px solid",
                    borderColor: "gray.100",
                }            
            }
        },
        tbody:{
            td:{
                fontSize: "0.85rem",
                py:3,
                px:3,
                // fontSize: "13px",
                color: 'gray.500',
                bg: "white",                
                maxW: '35%',
                borderWidth: "1px",
                borderTopWidth: "0",
                borderBottomWidth: "0",
                transition: "all 0.3s ease-in",
                verticalAlign: 'middle',
                "&:first-of-type":{                    
                    pl: 12
                },             
                "&.title":{
                    fontSize: "0.95rem",
                    fontWeight: '500',
                    // color: 'primary.500'
                },
                "&.details-row":{
                    transition: "all 0.3s ease-in",
                },
                "&[data-align='right']":{
                    textAlign: "right"
                },  
                "&[data-color='green']":{
                    textAlign: "center",
                    bg: "green.100  !important"
                },  
                "&[data-color='yellow']":{
                    textAlign: "center",
                    bg: "yellow.100  !important"
                },  
                "&[data-color='red']":{
                    textAlign: "center",
                    bg: "red.100  !important"
                }, 
                "&.unstyled":{
                    p: "0 !important",
                    borderWidth: "0px !important",
                    borderBottomWidth: "0px !important",
                    "&:hover":{
                        bg: "transparent !important"
                    }
                },
                borderBottom: "1px dashed",
                borderColor: "gray.100",              
            },
            tr:{
                transition: "all 0.3s ease-in",
                "&.expanded":{
                    td:{
                        bg: "gray.100 !important"
                    }
                },
                "&:hover":{
                    td:{
                        bg: 'gray.50'
                    }
                }
            }

        }
    },    
    ".react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text":{
        lineHeight: 'var(--chakra-lineHeights-10)',
        
    },
    ".react-datepicker__month .react-datepicker__month--selected":{
        bg: 'var(--chakra-colors-brand-500)',
        "&:hover":{
            bg: 'var(--chakra-colors-brand-600)',
        }
    },
    ...datepicker
}   
