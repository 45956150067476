import {requestTimeout, handleResponse} from '../utils'

const apiUrl = process.env.REACT_APP_API_URL
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

export const contentApi = {    
    _get,
};


async function _get(slug) {    

    const requestOptions = {
        method: 'GET',        
        headers: {            
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        }
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'contents/'+slug, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    
}

