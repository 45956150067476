import React from 'react'
import { Button } from '@chakra-ui/react'
import { Icon } from '.'
import { NavLink } from 'react-router-dom'

const NavButton = ({to, icon, label, showIcon, colorScheme, ...rest }) => {
    return(
        <Button 
            as={NavLink} 
            to={to} 
            fontWeight="600"             
            {...(showIcon && {leftIcon: <Icon mr="1.5" fontSize="22px" name={icon} color="gray.300" />}) }            
            _active={{ bg: "transparent" }} 
            bg="transparent" 
            _hover={{ bg: "transparent", textDecoration: 'none', _text:{textDecoration: 'none'}}} 
            borderLeft="3px solid transparent" 
            rounded="none" 
            color="gray.500"
            fontSize="16px" 
            shadow="none" 
            w="100%"      
            h="46px"
            px={10}
            justifyContent="flex-start" 
            textAlign="left" 
            pl={showIcon ? 6 : 9}
            className={`${"dash-nav "+ colorScheme || "" }`} 
            {...rest}
            > 
            {label} 
        </Button>
    )
}

export { NavButton }