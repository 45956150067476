export const AuthConstants = {
    LOGIN: 'AUTH_LOGIN',
    LOGOUT: 'AUTH_LOGOUT',
    ME: 'AUTH_PROFILE',
    FAILED: 'AUTH_FAILED',
    AUTH: 'CHECK_USER_AUTH',
    CLEAR: 'AUTH_CLEAR'
}


export const CourseConstants = {    
    ALL: 'GET_ALL_COURSES',
    FEATURED: 'GET_FEATURED_COURSES',
    MY_COURSES: 'GET_USER_COURSES',
    MY_COURSE_DETAIILS: 'GET_USER_COURSE_DETAILS',
    GET: 'GET_COURSE',
    BUY_COURSE: 'BUY_COURSE',
    FAILED: 'COURSE_FAILED'
}


export const TopicConstants = {    
    GET: 'GET_TOPIC_DETAILS',        
    FAILED: 'TOPIC_FAILED',
    CLEAR: 'TOPIC_CLEAR'
}
