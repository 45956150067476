import fetchIntercept from 'fetch-intercept'
import { requestTimeout } from '../utils'
import { AppStore } from '../redux'
import { authAction } from '../redux/actions'
// import { authApi } from '.';
import Cookies from 'js-cookie'

const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const auth_cookie = process.env.REACT_APP_AUTH_COOKIE
const apiUrl = process.env.REACT_APP_API_URL
var in_progress = false
var is_logout = false
var refreshSubscribers=[]
var dataRequests = {}


const getRelativeUrl = url => url.replace(window.location.origin, '');

const onRefreshed = () => {
    refreshSubscribers.map(callback => callback());
    refreshSubscribers = [];
};

const subscribeTokenRefresh = callback => {
    refreshSubscribers.push(callback);
};


const removeDataRequestsItem = requestKey => {
    const { [requestKey]: _omit, ...remaining } = dataRequests;
    dataRequests = remaining;
};



const refreshToken = async() => {
    let refresh_token = Cookies.get(auth_cookie)
    if(refresh_token)
    {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body:JSON.stringify({
                    "grant_type": "refresh_token",
                    "client_id": process.env.REACT_APP_OAUTH_CLIENT_ID,
                    "client_secret": process.env.REACT_APP_OAUTH_CLIENT_SECRET,
                    "refresh_token": refresh_token
            })
        };
        let data = await requestTimeout(apiMaxTime,fetch(apiUrl + 'oauth/token', requestOptions)) 
        if(data?.ok){
            let token_data = await data.json();
            localStorage.setItem(accessToken, token_data.access_token)
            Cookies.set(auth_cookie, token_data?.refresh_token, { domain: process.env.REACT_APP_DOMAIN, expires: 15 })
            is_logout = false  
            return Promise.resolve()
        }else{
            is_logout = true  
            localStorage.removeItem(accessToken)
            Cookies.remove(auth_cookie,  { domain: process.env.REACT_APP_DOMAIN})
            return data
        }
    }
    localStorage.removeItem(accessToken)
    Cookies.remove(auth_cookie,  { domain: process.env.REACT_APP_DOMAIN})
    is_logout = true  
    return Promise.reject()
        
}

export const interceptor = fetchIntercept.register({    

    request: function (url, config) {     
        if (config && !url?.includes('oauth/token')){        
            dataRequests = {
                ...dataRequests,
                [`${getRelativeUrl(url)}_${config.method || 'GET'}`]: config,
            };
        }                
        return [url, config];
    },
 
    requestError: function (error) {        
        return Promise.reject(error)  
    },
 
    response: async function (response) {   
        
        const requestKey = `${getRelativeUrl(response.url)}_${response.request.method}`;
        if (response.status === 401 && !response.url.includes('oauth/token')) {            
            if(!in_progress){
                in_progress = true                    
                refreshToken()
                    .then((res) => {
                        in_progress= false
                        onRefreshed()
                    })
                    .catch((error) => {
                        is_logout = true
                    })                                                 
            }
            
            if(is_logout){            
                is_logout = false
                in_progress = false
                dataRequests={}                
                await AppStore.dispatch(authAction.logout())                
                return response
            }
            // else{                
                const retryOrigReq = new Promise((resolve, reject) => {
                    subscribeTokenRefresh(async () => {                    

                        let new_request = {...dataRequests[requestKey]}
                        new_request['headers']['Authorization'] = 'Bearer '+localStorage.getItem(accessToken)
                        try {
                            const origReqResponse = await fetch( response.url, new_request )                        
                            resolve( origReqResponse )
                            removeDataRequestsItem( requestKey )
                        } catch ( err ) {
                            reject( err )
                        }
                    });
                });

                return retryOrigReq;
            // }
            
        }            
        removeDataRequestsItem(requestKey);
        return response;
    },
 
    responseError: function (error) {    
        
        if(error?.message === 'Failed to fetch'){
            return Promise.reject({error: 'Failed to fetch' , message: 'API request has been blocked by CORS policy'})
        }else{            
            return Promise.reject(error)               
        }
    }
});

// interceptor();
