import dayjs from 'dayjs'

export const handleResponse = (response) => {        
    return response.text()
        .then(async (my_data) => {     

            // console.log("<<<DATA>>>", my_data)
            if(response.status === 500)
            {
                return Promise.reject({error: 'Error 500: Internal Server Error.'})
            }
            if(response.status === 404)
            {
                
                // window.toast({
                //     position: "top", 
                //     title: "Error 404", 
                //     description: "Requested uri does not exist.", 
                //     status: "error",
                //     isClosable: true,
                //     duration: 2500
                // })
                return Promise.reject({error: 'Error 404: Requested uri does not exist.'})
                // return Promise.reject({error: 'Error 404: Requested uri does not exist.'})
            }

            // if(response.status === 401)
            // {            
            //     // AppStore.dispatch(AuthActions.logout())
            //     return Promise.reject({message: 'Unauthorised Access'})
                
            // }

            if(response.status === 400 || response.status === 400)
            {                
                
                const data = my_data && JSON.parse(my_data);
                return Promise.reject(data)
            }

            if(response.status === 422)
            {                
                const data = my_data && JSON.parse(my_data);
                return Promise.reject({errors: data, message: 'Form validation failed!'})
            }

            const data = my_data && JSON.parse(my_data);
            if (!response.ok) {
                // throw Error(response.statusText);
                const error = (data?.error || data?.errors) || response.statusText;
                const message = data?.message || null
                return Promise.reject({error, message, data});
            }

            return data
        })
        .catch(error => {
            return Promise.reject(error);
        });
}

export const requestTimeout = (ms, promise) => {
    return new Promise((resolve, reject) => {
        const timer = setTimeout(() => {
        reject({error:'Server Request Timeout'})
        }, ms)      
        promise
        .then(value => {
            clearTimeout(timer)
            resolve(value)
        })
        .catch(reason => {
            clearTimeout(timer)            
            reject(reason)
        })
    })
}



var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)


window.titleCase = (str) =>{
    return str.replace(
        /\w\S*/g,
        function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}


window.fromNow = (timestap) => {
    return dayjs(timestap).fromNow()
}


window.is_empty = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}


window.moneyFormat = (number, token="Rs.", code="PKR", format=false) => {
    if(number){
        let value = parseFloat(number)
        if(!format)
            return token + ""+(value).toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        else
            return code + "" + token+(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }else{
        return 0
    }
}


window.padNum = (n, width = 2, z= null) => {    
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;    
}

window.moneyInWords = (n) => {

    const arr = x => Array.from(x);
    const num = x => Number(x) || 0;
    
    const isEmpty = xs => xs.length === 0;
    const take = n => xs => xs.slice(0,n);
    const drop = n => xs => xs.slice(n);
    const reverse = xs => xs.slice(0).reverse();
    const comp = f => g => x => f (g (x));
    const not = x => !x;
    const chunk = n => xs => isEmpty(xs) ? [] : [take(n)(xs), ...chunk (n) (drop (n) (xs))];

    let a = [
      '', 'one', 'two', 'three', 'four',
      'five', 'six', 'seven', 'eight', 'nine',
      'ten', 'eleven', 'twelve', 'thirteen', 'fourteen',
      'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
    ];
    let b = [
      '', '', 'twenty', 'thirty', 'forty',
      'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
    ];
    let g = [
      '', 'thousand', 'million', 'billion', 'trillion', 'quadrillion',
      'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion'
    ];
    // this part is really nasty still
    // it might edit this again later to show how Monoids could fix this up
    let makeGroup = ([ones,tens,huns]) => {
      return [
        num(huns) === 0 ? '' : a[huns] + ' hundred ',
        num(ones) === 0 ? b[tens] : (b[tens] && b[tens] + '-') || '',
        a[tens+ones] || a[ones]
      ].join('');
    };
    // "thousands" constructor; no real good names for this, i guess
    let thousand = (group,i) => group === '' ? group : `${group} ${g[i]}`;
    // execute !
    if (typeof n === 'number') return window.moneyInWords(String(n));
    if (n === '0')             return 'zero';
    let ret = comp (chunk(3)) (reverse) (arr(n))
                .map(makeGroup)
                .map(thousand)
                .filter(comp(not)(isEmpty))
                .reverse()
                .join(' ');
    return window.titleCase(ret + ' rupees only!')
  };

