import {requestTimeout, handleResponse} from '../utils'
import Cookies from 'js-cookie'
const apiUrl = process.env.REACT_APP_API_URL
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME
const auth_cookie = process.env.REACT_APP_AUTH_COOKIE


export const authApi = {
    _login,
    _logout,
    _register,
    _request_otp,
    _check_otp,
    _change_password,
    _auth_check,
    _forget_password,
    _check_reset,
    _reset_password,    
    _me
};


async function _logout(){
        
    Cookies.remove(auth_cookie)
    const app_token = localStorage.getItem(accessToken)

    const requestOptions = {
        
        method: 'GET',        
        headers: {            
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + app_token
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'logout', requestOptions))
                .then(handleResponse)
                .then( () => {                    
                    return true;
                });    
}

async function _login(form_data) {    
    let auth_data = {
        ...form_data,
        grant_type: "password",
        client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
        client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
    }

    const requestOptions = {
        method: 'POST',        
        headers: {            
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(auth_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'oauth/token', requestOptions))
                .then(handleResponse)
                .then((response) => {    
                    if(response?.access_token){
                        
                        localStorage.setItem(accessToken, response.access_token)
                        Cookies.set(auth_cookie, response?.refresh_token, { domain: process.env.REACT_APP_DOMAIN, expires: 15})
                        return {user: response.user, token: response.access_token};                    
                    }                    
                })                
        
}

async function _register(form_data) {        
    const requestOptions = {
        method: 'POST',        
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'register', requestOptions))
                .then(handleResponse)
                .then((response) => {
                    return response
                })                    
}


async function _request_otp(form_data) {    
    
    const requestOptions = {
        method: 'POST',
        credentials: "include",
        headers: {            
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'otp-request', requestOptions))
                .then(handleResponse)
                .then((response) => {
                    return response
                })                    
}

async function _check_otp(form_data) {    
    
    const requestOptions = {
        method: 'POST',    
        headers: {
    
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'otp-confirm', requestOptions))
                .then(handleResponse)
                .then((response) => {
                    return response
                })                    
}

async function _change_password(form_data) {        
    const requestOptions = {
        method: 'POST',
        headers: {            
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'otp-change-password', requestOptions))
                .then(handleResponse)
                .then((response) => {
                    return response
                })                    
}

async function _forget_password(form_data) {
    let token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'forget-password', requestOptions))
        .then(handleResponse)
        .then((response) => {
            return response
        })        
    
}

async function _reset_password(form_data) {
    let token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'reset-password', requestOptions))
                .then(handleResponse)
                .then((response) => {
                    return response
                })                
    
}

async function _check_reset(form_data) {
    let token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'check-reset', requestOptions))    
                .then(handleResponse)
                .then((response) => {
                
                    return response
                })

    
}


async function _auth_check() {
    let token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'GET',
        headers: {            
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    };
    
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'auth', requestOptions))
                .then(handleResponse)                
                .then((response) => {                    
                    return response
                })
                
}


async function _me(){
        
    let token = localStorage.getItem(accessToken)
    const requestOptions = {
        method: 'GET',         
        headers: {            
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'auth', requestOptions))                    
                .then(handleResponse)
                .then((response) => {    
                    return {user: response.user}
                })  
}
