import { CourseConstants as Constants } from '../constants'
import { courseApi as api } from '../../api'

export const courseAction = {
    all,
    get,
    featured,
    buy_course,
    my_courses,
    get_details,
    clear,
};

function all() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._all()
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.ALL, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function get(slug) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._get(slug)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.GET, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function featured() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._featured()
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.FEATURED, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function buy_course(form_data) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._buy_course(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.BUY_COURSE, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function my_courses() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._my_courses()
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.MY_COURSES, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function get_details(slug) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._get_details(slug)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.MY_COURSE_DETAIILS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function clear() {
    return dispatch => {   
        return new Promise((resolve, reject) => {     
            dispatch(success());
            resolve()
        })   
    }
    function success() { return { type: Constants.CLEAR } }
}