import React from 'react'
import { 
    Modal as ChakraModal, ModalBody, ModalContent, 
    ModalOverlay, ModalCloseButton, 
    ModalFooter, 
    Heading
} from '@chakra-ui/react'


const Modal = (props) => {

    const modalRef= React.createRef()
    let {open, size, onClose, bg, transition, closeable=true, footer, title, placement, ...rest} = props
    return(
        <ChakraModal
            isOpen={open || false} 
            size={size || "lg"}  
            onClose={onClose}             
            autoFocus={false}
            initialFocusRef={modalRef}
            closeOnOverlayClick={closeable}
            closeOnEsc={closeable}
            // placement={placement || "right"}
            blockScrollOnMount={true}
            {...rest}            
            >
            <ModalOverlay />
            <ModalContent rounded="3xl">
                {/* {
                    title &&
                    <ModalHeader px="7" fontSize="18px" fontWeight="700" color="gray.600" py="10px" borderBottom="1px solid" borderColor="gray.200" bg="white">{title}</ModalHeader>
                } */}
                
                
                
                <ModalBody p={props.p}   bg={bg || 'white'} rounded="3xl">
                    { title && <Heading color="brand.500" size="md" mb={5}>{title}</Heading>}
                    <ModalCloseButton rounded="full" _focus={{ outline: "none" }} />
                    {props.children}
                </ModalBody>
                {
                    footer &&
                    <ModalFooter borderTop="0px solid" borderColor="gray.200" roundedBottom="3xl">
                        {footer}
                    </ModalFooter>
                }
                
                <div ref={modalRef}/>
            </ModalContent>    
        </ChakraModal>
    )
}

export {Modal}