import { AuthConstants as Constants } from '../constants'


const initialState = {
    user: {},
    loggedIn: false
};

export function authReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.LOGIN:
            return {
                ...state,
                loggedIn: true,
                token: action?.payload?.token,
                user: action.payload.user,
            };
                  
        case Constants.ME:
            return{
                ...state,
                user: action.payload.user,
            };
       
        case Constants.LOGOUT:

            console.log("Here")
            return{
                ...state,
                loggedIn: false,                
                user: null,
            };
                   
        case Constants.FAILED:
            return{
                ...state
            }
        case Constants.CLEAR:
        default:
            return state
    }
}