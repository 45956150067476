import {requestTimeout, handleResponse} from '../utils'

const apiUrl = process.env.REACT_APP_API_URL
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME
const accessToken = process.env.REACT_APP_ACCESS_TOKEN


export const courseApi = {
    _all,
    _get,
    _featured,
    _buy_course,
    _my_courses,
    _get_details,
    
};


async function _all() {    
    const app_token = localStorage.getItem(accessToken)

    const requestOptions = {
        method: 'GET',
        headers: {            
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + app_token
        }
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'courses', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    
}


async function _get(slug) {

       
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        }
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'courses/'+slug, requestOptions))                    
                .then(handleResponse)
                .then((response) => {    
                    return response
                })                
        
}

async function _featured() {     
    
    const requestOptions = {
        method: 'GET',        
        headers: {            
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
            
        }
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'courses/featured', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    
}

async function _buy_course(form_data) {     
        
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'courses/purchase', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    
}

async function _my_courses() {     
        
    const requestOptions = {
        method: 'GET',
        headers: {            
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken),
            'Accept': 'application/json',
        }
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'account/courses', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    
}

async function _get_details(slug) {     
        
    const requestOptions = {
        method: 'GET',
        headers: {            
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        }
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'account/courses/'+slug, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    
}

