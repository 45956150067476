import {requestTimeout, handleResponse} from '../utils'

const apiUrl = process.env.REACT_APP_API_URL
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME


export const topicApi = {    
    _get,       
    _get_file, 
    _get_audio,
};



async function _get(slug) {
   
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        }
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'account/courses/topics/'+slug, requestOptions))                    
                .then(handleResponse)
                .then((response) => {    
                    return response
                })                
        
}

async function _get_file(path) {

   
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        }
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'account/'+path, requestOptions))                    
                .then(handleResponse)
                .then((response) => {    
                    return response
                })                
        
}



async function _get_audio(path) {

   
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        }
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'account/'+path, requestOptions))                    
                // .then((response) => response.blob())                
                .then((res) => {    
                    // return URL.createObjectURL(res);
                    return res;
                })                
        
}