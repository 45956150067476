import { CourseConstants as Constants } from '../constants'
const initialState = {
    courses: [],   
    course:{},
    featured: [],
    my_courses: [],
    my_course: null
};

export function courseReducer(state = initialState, action) {
    switch (action.type) {  
        
        case Constants.ALL:
            return {
                ...state,
                courses: action.payload.courses
            };

        case Constants.GET:
            return {
                ...state,
                course: action.payload.course
            };

        case Constants.MY_COURSE_DETAIILS:
            return {
                ...state,
                my_course: action.payload.course
            };

        case Constants.MY_COURSES:
            return {
                ...state,
                my_courses: action.payload.courses
            };

        case Constants.FEATURED:
            return {
                ...state,
                featured: action.payload.courses
            };

                 
                   
        case Constants.FAILED:
            return{
                ...state
            }

        case Constants.CLEAR:
            return {
                courses: []
            }

        case Constants.BUY_COURSE:
            return {
                ...state
            }

        case Constants.CLEAR_ONE:
            return {
                course: {}
            }
        
        default:        
            return state
    }
}