import { TopicConstants as Constants } from '../constants'
const initialState = {
    topic: null,       
};

export function topicReducer(state = initialState, action) {
    switch (action.type) {  
        
        
        case Constants.GET:
            return {
                ...state,
                topic: action.payload.topic
            };

                   
        case Constants.FAILED:
            return{
                ...state
            }

        case Constants.CLEAR:
            return {
                topic: null
            }
        
        default:        
            return state
    }
}