import React from 'react'
import './utils'
import { ChakraProvider, createStandaloneToast } from "@chakra-ui/react"
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux';
import { AppStore, persistor } from './redux'
import { AlertProvider, AppProvider } from './services'
import { HelmetProvider } from 'react-helmet-async'
import {theme} from './theme'
import Routes from './routes'
import { BrowserRouter as Router } from 'react-router-dom'

const { ToastContainer } = createStandaloneToast()

const App = () => {    
 
    return (
        <Provider store={AppStore}>
            <PersistGate loading={null} persistor={persistor}>
                <ChakraProvider theme={theme}>  
                    <ToastContainer />  
                    <HelmetProvider>
                        <AlertProvider>
                            <AppProvider>   
                                <Router>
                                    <Routes />                                                              
                                </Router>
                            </AppProvider>          
                        </AlertProvider>
                    </HelmetProvider>
                </ChakraProvider>
            </PersistGate>
        </Provider>
      )
}

export default App
