import { combineReducers } from 'redux'
import { authReducer } from './auth.reducer'
import { courseReducer } from './course.reducer';
import { topicReducer } from './topic.reducer';


export default combineReducers({
    auth: authReducer,
    courses: courseReducer,
    topics: topicReducer
});
